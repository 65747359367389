import React, {useEffect, useState} from 'react';
import useHttp from "../hooks/use-http";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SideBar} from "../components/SideBar";
import {NewsListItem} from "../components/NewsListItem";
import {NewsArchiveControl} from "../components/NewsArchiveControl";
import {ArticleTagsControl} from "../components/ArticleTagsControl";
import {ArticleListItem} from "../components/ArticleListItem";
import {useNavigate, useParams} from "react-router-dom";
import Card from "react-bootstrap/Card";
import {Badge} from "react-bootstrap";

export const ArticlePage = (props) => {
    const [articleList, setArticleList] = useState([]);
    const [currentTag, setCurrentTag] = useState({});

    const {error, isLoading, sendRequest: getContent} = useHttp();

    const params = useParams();
    const tag = params.tag;

    const navigate = useNavigate();

    const removeTagHandler = function () {
        setCurrentTag({});
        navigate('/articles');
    }

    useEffect(() => {

        const transformResponse = (data) => {
            console.log(data);

            setArticleList(data.payload.articles);
            setCurrentTag(data.payload.tag);
        };

        let apiUrl = '/articles/list';

        if (tag) {
            apiUrl = apiUrl + '/tag/' + tag;
        }


        getContent({url: apiUrl}, transformResponse);
    }, [tag]);

    return (
        <>
            <Container fluid>
                <Row className="pageBody" >
                    <Col className="d-none d-md-block" md={2}><SideBar side="left"/></Col>
                    <Col xs={12} md={8}>
                        <Card>
                            <Card.Header><h2>About the wood</h2></Card.Header>
                            {tag && <Card.Text>Tags: <Badge onClick={removeTagHandler}>{tag} x</Badge></Card.Text>}
                        </Card>
                        {!isLoading && !error && articleList.map((articleItem) => {
                            return (
                                <ArticleListItem
                                    key={articleItem.id}
                                    id={articleItem.id}
                                    title={articleItem.title}
                                    intro={articleItem.intro}
                                    body={articleItem.body}
                                    image={articleItem.image}
                                    slug={articleItem.slug}
                                    date={articleItem.publishedAt}
                                />
                            )
                        })}
                    </Col>
                    <Col md={2}><ArticleTagsControl /></Col>
                </Row>
            </Container>
        </>
    )
}