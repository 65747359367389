import react from 'react';
import Card from 'react-bootstrap/Card';
import './HomeItemCss.css';
import Nav from 'react-bootstrap/Nav';
import {Link} from "react-router-dom";
import "../../pages/HomePage.css";

export const HomeEventItem = (props) => {

    console.log(props);

    const clickHandler = function() {
        console.log('hello');
    }

    return (
        <>
            <Card>
                <Card.Header as="h5">
                    Our Next Event
                </Card.Header>
                <Card.Title><Link to={`/events/` + props.item.id + `/` + props.item.slug}>{props.item.title}</Link></Card.Title>
                <Card.Text>{props.item.intro}</Card.Text>
                <Card.Footer className="pull-right"><Link to={'/events'}>View the calendar for more upcoming events</Link></Card.Footer>
            </Card>
        </>
    )
}