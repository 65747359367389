import react from 'react';
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";

export const SideBar = (props) => {

    let blocks;

    if (props.side === 'left') {
        blocks = [
            {
                "id": 1,
                "title": "Volunteering opportunities",
                "content": "Find out more about how you can help with conservation in the wood",
                "articleId": 4
            },
            {
                "id": 2,
                "title": "Reporting an issue",
                "content": "If you need to alert someone to an issue, this article should help point you in the right direction",
                "articleId": 3
            }
        ]
    } else {
        blocks = [
            {
                "id": 3,
                "title": "About the Friends",
                "content": "Find out more about what we do and how you too can become a member",
                "articleId": 1
            }
        ]
    }

    return (
        <>
            {blocks.map(block => {
                return <Card key={block.id}>
                    <Card.Header>{block.title}</Card.Header>
                    <Card.Text>{block.content}</Card.Text>
                    <Card.Text><Link to={'/articles/item/' + block.articleId + '/detail'}>see more</Link> </Card.Text>
                </Card>

            })}
        </>
    )
}