import react from 'react';
import Card from 'react-bootstrap/Card';
import './HomeItemCss.css';
import Nav from 'react-bootstrap/Nav';
import {Link} from "react-router-dom";

export const HomeNewsItem = (props) => {

    return (
        <>
            <Card>
                <Card.Header as="h5">
                    Our Latest News
                </Card.Header>
                <Card.Title><Link to={`/news/` + props.item.id + `/` + props.item.slug}>{props.item.title}</Link></Card.Title>
                <Card.Text>{props.item.intro}</Card.Text>
                <Card.Footer><Link to={'/news'}>See more news from the wood</Link></Card.Footer>
            </Card>
        </>
    )
}