import React, {useEffect, useState, useCallback} from 'react';
import useHttp from "../hooks/use-http";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SideBar} from "../components/SideBar";
import {NewsListItem} from "../components/NewsListItem";
import {NewsArchiveControl} from "../components/NewsArchiveControl";
import {useNavigate, useParams} from "react-router-dom";
import Card from "react-bootstrap/Card";
import {Badge} from "react-bootstrap";
import {Loading} from "../components/Loading";

export const NewsPage = (props) => {

    const [newsList, setNewsList] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [currentDateKey, setCurrentDateKey] = useState('');

    const {error, isLoading, sendRequest: getContent} = useHttp();
    const params = useParams();
    const dateKey = params.dateKey;

    const navigate = useNavigate();

    const clearFilter = function() {
        setFilterText('');
        navigate('/news');
    }

    useEffect(() => {

        const transformResponse = (data) => {
            console.log(data);

            setNewsList(data.payload.news);

            if (data.payload.filterText && data.payload.filterText.length > 1) {
                setFilterText(data.payload.filterText);
            }
        };

        let targetUrl = '/news/list';

        if (dateKey) {
            targetUrl = targetUrl + '/' + dateKey;
        }


        getContent({url: targetUrl}, transformResponse);
    }, [dateKey]);

    return (
        <>
            <Container fluid>
                <Row className="pageBody" >
                    <Col className="d-none d-md-block" md={2}><SideBar side="left"/></Col>
                    <Col xs={12} md={8}>
                        <Card>
                            <Card.Header><h2>News from the wood</h2></Card.Header>
                            {filterText.length > 1 && <Card.Text>Filtered for <Badge onClick={clearFilter}>{filterText} x</Badge></Card.Text>}
                        </Card>

                        {isLoading && <Loading />}
                        {!isLoading && !error && newsList.map((newsItem) => {
                            return (
                                <NewsListItem
                                    key={newsItem.id}
                                    id={newsItem.id}
                                    title={newsItem.title}
                                    intro={newsItem.intro}
                                    body={newsItem.body}
                                    image={newsItem.image}
                                    slug={newsItem.slug}
                                    date={newsItem.publishedAt}
                                    caption={newsItem.caption}
                                />
                            )
                        })}
                    </Col>
                    <Col md={2}><NewsArchiveControl /></Col>
                    <Col className="d-md-none" md={2}><SideBar side="left"/></Col>
                </Row>
            </Container>
        </>
    )
}