import "./HeaderBar.css";

function HeaderBar() {
    return (
        <>
            <header id="header-bar">
                The Friends of Queen's Wood
            </header>
        </>
    )
}

export default HeaderBar;