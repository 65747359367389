import React, {useEffect, useState} from 'react';
import useHttp from "../hooks/use-http";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";

export const NewsArchiveControl = () => {

    const [controlList, setControlList] = useState([]);

    const {error, isLoading, sendRequest: getContent} = useHttp();

    useEffect(() => {

        const transformResponse = (data) => {
            console.log(data);

            setControlList(data.payload.control);
        };


        getContent({url: '/news/archives/list'}, transformResponse);
    }, []);

    return (
        <>
            <Card>
                <Card.Header>Archives</Card.Header>
                <Card.Text>
                    <ul>
                        {!isLoading && !error && controlList.map(item => {
                            return <li key={item.key}><Link to={'/news/for-date/' + item.key}>{item.label}</Link></li>
                        })}
                    </ul>
                </Card.Text>
            </Card>
        </>
    )
}