import Card from 'react-bootstrap/Card';
import './HomeItemCss.css';
import {Link} from "react-router-dom";

export const HomeArticleItem = (props) => {
    return (
        <>
            <Card>
                <Card.Header as="h5">
                    From 'About the wood'
                </Card.Header>
                <Card.Title><Link to={`/articles/item/` + props.item.id + `/` + props.item.slug}>{props.item.title}</Link></Card.Title>
                <Card.Text>{props.item.intro}</Card.Text>
                <Card.Footer><Link to={'/articles'}>Browse our list of articles</Link></Card.Footer>
            </Card>
        </>
    )
}