import {Outlet} from "react-router-dom";
import HeaderBar from "../components/HeaderBar";
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import {Navigation} from "../components/Navigation";
import "./RootLayout.css";
import {Footer} from "../components/Footer";

export const RootLayout = () => {
    return (
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        >
            <HeaderBar />
            <Navigation />
            <div className="page-body">
            <Outlet />
            </div>
            <Footer />

        </ThemeProvider>
    )
}