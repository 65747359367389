import React, { useRef } from 'react';
import { Editable, useEditor } from '@wysimark/react'
import {Button} from "react-bootstrap";

export default function PageEditor() {

    const editor = useEditor({
        initialMarkdown: "# Hello World\n\nLorem ipsum dolar.",
        height: 240
    });

    function getMarkdownHandler() {
        console.log(editor.getMarkdown());
    }

    return (
        <>
            <Editable editor={editor} />
            <Button onClick={getMarkdownHandler}>Get markdown</Button>
        </>

    );
}