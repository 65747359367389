import React, {useEffect, useState, useCallback} from 'react';
import useHttp from "../hooks/use-http";
import {HomeNewsItem} from "../components/Home/HomeNewsItem";
import {HomeEventItem} from "../components/Home/HomeEventItem";
import {HomeArticleItem} from "../components/Home/HomeArticleItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SideBar} from "../components/SideBar";
import Container from "react-bootstrap/Container";
import "./HomePage.css";
import Card from "react-bootstrap/Card";

export const Error404 = () => {

    return (
        <>
            <Container fluid>
                <Row className="pageBody" >
                    <Col md={2}><SideBar side="left"/></Col>
                    <Col xs={8}>
                        <Card>
                            <Card.Header>404 Page Not Found</Card.Header>
                            <Card.Text>The requested page could not be found.</Card.Text>
                            <Card.Text>Please use the navigation menu above to browse our site. Thank you.</Card.Text>
                        </Card>
                    </Col>
                    <Col md={2}><SideBar /></Col>
                </Row>
            </Container>
        </>
    )
}
