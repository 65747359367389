import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SideBar} from "../components/SideBar";
import Card from "react-bootstrap/Card";
import Figure from "react-bootstrap/Figure";
import ReactMarkdown from "react-markdown";
import {ArticleTagsControl} from "../components/ArticleTagsControl";
import useHttp from "../hooks/use-http";
import {useParams} from "react-router-dom";
import {format} from "date-fns";

export const Membership = (props) => {

    const [article, setArticle] = useState([]);
    const [itemDate, setItemDate] = useState('');
    const {error, isLoading, sendRequest: getContent} = useHttp();
    const articleId = 2;

    useEffect(() => {

        const transformResponse = (data) => {
            console.log(data);

            setArticle(data.payload.article);

            let articleDate = new Date(article.publishedAt);
            let formattedDate =  format(articleDate, 'MMMM do, yyyy');
            setItemDate(formattedDate);
        };

        let targetUrl = '/articles/item/' + articleId + '/detail';

        getContent({url: targetUrl }, transformResponse);


    }, [articleId]);

    return (
        <>
            <Container fluid>
                <Row className="pageBody" >
                    <Col className="d-none d-md-block" md={2}><SideBar side="left"/></Col>
                    <Col xs={12} md={8}>
                        <Card>
                            <Card.Header>
                                {article.title}
                                {article.standalone === false && <span className="news-date">{itemDate}</span>}
                            </Card.Header>
                            <Row>
                                <Col className="d-none d-md-block" md={4}>
                                    <Figure className="news-item-image">
                                        <Figure.Image
                                            width={250}
                                            height={250}
                                            alt="image"
                                            src={article.image}
                                        />
                                        <Figure.Caption>{article.caption}</Figure.Caption>
                                    </Figure>
                                </Col>
                                <Col md={8}>
                                    <Card.Text as="div"><ReactMarkdown>{article.intro}</ReactMarkdown></Card.Text>
                                    <Card.Text as="div"><ReactMarkdown>{article.body}</ReactMarkdown></Card.Text>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={2}>
                        {article.standalone === false && <ArticleTagsControl />}
                        {article.standalone === true && <SideBar />}
                    </Col>
                </Row>
            </Container>
        </>
    )
}
