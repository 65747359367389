import React from 'react';

import './EventDate.css';
export default function EventDate(props) {

    const month = props.eventDate.toLocaleString('en-US', {month: 'long'});
    const day = props.eventDate.toLocaleString('en-US', {day: '2-digit'});

    return (
        <div className="event-date-container">
            <div className="event-date-month">{month}</div>
            <div className="event-date-day">{day}</div>
        </div>
    );
}