import React, {useEffect, useState} from 'react';
import useHttp from "../hooks/use-http";
import {useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SideBar} from "../components/SideBar";
import Card from "react-bootstrap/Card";
import Figure from "react-bootstrap/Figure";
import ReactMarkdown from "react-markdown";
import EventDate from "../components/EventDate";

export const EventItem = (props) => {

    const [eventItem, setEventItem] = useState([]);
    const {error, isLoading, sendRequest: getContent} = useHttp();
    const params = useParams();

    const eventId = params.id;

    useEffect(() => {

        const transformResponse = (data) => {
            console.log(data);

            setEventItem(data.payload.event);
        };

        let targetUrl = '/events/item/' + eventId + '/detail';

        getContent({url: targetUrl }, transformResponse);


    }, [eventId]);



    return (
        <>
            <Container fluid>
                <Row className="pageBody" >
                    <Col className="d-none d-md-block" md={2}><SideBar side="left"/></Col>
                    <Col xs={12} md={8}>
                        {!isLoading && !error && <Card>
                            <Card.Header>
                                {eventItem.title}
                            </Card.Header>
                            <Row>
                                <Col md={3}><EventDate eventDate={new Date(eventItem.startsAt)} /></Col>
                                <Col md={6}>
                                    <Card.Text as="div"><ReactMarkdown>{eventItem.intro}</ReactMarkdown></Card.Text>
                                    <Card.Text as="div"><ReactMarkdown>{eventItem.body}</ReactMarkdown></Card.Text>
                                </Col>
                                <Col className="d-none d-md-block">
                                    <Figure className="news-item-image">
                                        <Figure.Image
                                            width={250}
                                            height={250}
                                            alt="image"
                                            src={eventItem.image}
                                        />
                                        <Figure.Caption>{eventItem.caption}</Figure.Caption>
                                    </Figure>
                                </Col>
                            </Row>
                        </Card>}
                    </Col>
                    <Col md={2}><SideBar /></Col>
                </Row>
            </Container>
        </>
    )
}