import {useCallback, useState} from 'react';

const useHttp = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendRequest = useCallback(async (requestConfig, responseHandler) => {
        setIsLoading(true);
        setError(null);

        let apiUrl = `${process.env.REACT_APP_API_URL}/api` + requestConfig.url;

        try {
            const response = await fetch(
                apiUrl,
                {
                    method: requestConfig.method ? requestConfig.method : 'GET',
                    headers: requestConfig.headers ? requestConfig.headers : {},
                    body: requestConfig.body ? JSON.stringify(requestConfig.body) : null
                }
            );

            if (!response.ok) {
                throw new Error("Request failed");
            }

            const data = await response.json();

            responseHandler(data);
        } catch (err) {
            setError(err.message || 'Something went wrong');
        }

        setIsLoading(false);
    }, []);

    return {
        isLoading,
        error,
        sendRequest
    };
};

export default useHttp;