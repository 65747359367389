import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Footer.css";
import {Link} from "react-router-dom";

export const Footer = () => {
    return (
            <footer className="page-footer footer-background">
                <Row>
                    <Col md={3}>
                        &copy; The Friends of Queen's Wood
                        <p>&nbsp;</p>
                    </Col>
                    <Col md={3}>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/news">News</Link></li>
                            <li><Link to="/events">Events</Link></li>
                        </ul>
                    </Col>
                    <Col md={3}>
                        <ul>
                            <li><Link to="/articles">About the wood</Link></li>
                            <li><Link to="/articles/item/1/about_the_friends">About the Friends</Link></li>
                            <li><Link to="/membership">Membership</Link></li>
                        </ul>
                    </Col>
                    <Col md={3}>
                        <p><a href="https://www.facebook.com/FriendsOfQueensWood/" target="_blank">Follow us on Facebook</a></p>
                        <p>
                            Contact:<br />
                            <a href="mailto:info@fqw.org.uk">info@fqw.org.uk</a>
                        </p>
                    </Col>
                </Row>
            </footer>
    )
}