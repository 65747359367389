import React, {useEffect, useState} from 'react';
import useHttp from "../hooks/use-http";
import {HomeNewsItem} from "../components/Home/HomeNewsItem";
import {HomeEventItem} from "../components/Home/HomeEventItem";
import {HomeArticleItem} from "../components/Home/HomeArticleItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SideBar} from "../components/SideBar";
import Container from "react-bootstrap/Container";
import "./HomePage.css";
import {Loading} from "../components/Loading";

export const HomePage = () => {

    const [newsItem, setNewsItem] = useState([]);
    const [eventItem, setEventItem] = useState([]);
    const [articleItem, setArticleItem] = useState([]);

    const {error, isLoading, sendRequest: getContent} = useHttp();

    useEffect(() => {

        const transformResponse = (data) => {
            setNewsItem(data.payload.content.news ? data.payload.content.news : {});
            setEventItem(data.payload.content.events ? data.payload.content.events : {});
            setArticleItem(data.payload.content.articles ? data.payload.content.articles : {});
        };

        getContent({url: '/cms/home'}, transformResponse);
    }, []);

    return (
        <>
            <Container fluid>
                <Row className="pageBody" >
                    <Col className="d-none d-md-block" md={2}><SideBar side="left"/></Col>
                    <Col xs={12} md={8}>
                        {isLoading && <Loading />}
                        {newsItem && <HomeNewsItem item={newsItem}/>}
                        {eventItem && <HomeEventItem item={eventItem} />}
                        {articleItem && <HomeArticleItem item={articleItem} />}
                    </Col>
                    <Col md={2}><SideBar /></Col>
                    <Col className="d-md-none" md={2}><SideBar side="left"/></Col>
                </Row>
            </Container>
        </>
    )
}
