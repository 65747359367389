import 'bootstrap/dist/css/bootstrap.min.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {HomePage} from "./pages/HomePage";
import {Membership} from "./pages/Membership";
import {NewsPage} from "./pages/NewsPage";
import {NewsItem} from "./pages/NewsItem";
import {EventsPage} from "./pages/EventsPage";
import {EventItem} from "./pages/EventItem";
import {ArticlePage} from "./pages/ArticlePage";
import {ArticleItem} from "./pages/ArticleItem";
import {RootLayout} from "./layouts/RootLayout";
import {Error404} from "./pages/Error404";
import PageEditor from "./pages/PageEditor";

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        children: [
            {
                path: '/',
                element: <HomePage />
            },
            {
                path: '/membership',
                element: <Membership />
            },
            {
                path: '/news',
                element: <NewsPage />
            },
            {
                path: '/news/for-date/:dateKey',
                element: <NewsPage />
            },
            {
                path: '/news/:id/:slug',
                element: <NewsItem />
            },
            {
                path: '/events',
                element: <EventsPage />
            },
            {
                path: '/events/:id/:slug',
                element: <EventItem />
            },
            {
                path: '/articles',
                element: <ArticlePage />
            },
            {
                path: '/articles/tags/:tag',
                element: <ArticlePage />
            },
            {
                path: '/articles/item/:id/:slug',
                element: <ArticleItem />
            },
            {
                path: '/editor',
                element: <PageEditor />
            },
            {
                path: '*',
                element: <Error404 />
            }
        ]
    }
]);

function App() {

    return <RouterProvider router={router} />;
}

export default App;
