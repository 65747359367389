import React, {useEffect, useState} from 'react';
import EventItem from "../components/EventItem";
import useHttp from "../hooks/use-http";
import Col from "react-bootstrap/Col";
import {SideBar} from "../components/SideBar";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {NewsArchiveControl} from "../components/NewsArchiveControl";
import Card from "react-bootstrap/Card";
import {Loading} from "../components/Loading";

export const EventsPage = () => {

    const [eventsList, setEventsList] = useState([]);


    const {error, isLoading, sendRequest: getContent} = useHttp();

    useEffect(() => {

        const transformResponse = (data) => {
            console.log(data);

            setEventsList(data.payload.events);
        };


        getContent({url: '/events/list'}, transformResponse);
    }, []);
    return (
        <>
            <Container fluid>
                <Row className="pageBody" >
                    <Col className="d-none d-md-block" md={2}><SideBar side="left"/></Col>
                    <Col xs={12} md={8}>
                        <Card>
                            <Card.Header><h2>Our upcoming events</h2></Card.Header>
                        </Card>
                        {isLoading && <Loading />}
                        {eventsList.map( eventItem => {
                            return (<EventItem
                                        key={eventItem.id}
                                        title={eventItem.title}
                                        intro={eventItem.intro}
                                        description={eventItem.body}
                                        date={eventItem.startsAt}
                            />)
                        })}
                    </Col>
                    <Col md={2}><SideBar /></Col>
                </Row>
            </Container>
        </>
    )
}
