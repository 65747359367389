import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SideBar} from "../components/SideBar";
import {NewsListItem} from "../components/NewsListItem";
import {NewsArchiveControl} from "../components/NewsArchiveControl";
import Card from "react-bootstrap/Card";
import useHttp from "../hooks/use-http";
import {format} from "date-fns";
import Figure from "react-bootstrap/Figure";
import "./NewsItem.css";
import {Link} from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import {useParams} from 'react-router-dom';

export const NewsItem = (props) => {

    const [newsItem, setNewsItem] = useState([]);
    const [itemDate, setItemDate] = useState('');
    const [otherStories, setOtherStories] = useState([]);
    const {error, isLoading, sendRequest: getContent} = useHttp();
    const params = useParams();

    const storyId = params.id;

    useEffect(() => {

        const transformResponse = (data) => {
            console.log(data);

            setNewsItem(data.payload.item);
            setOtherStories(data.payload.other);

            let articleDate = new Date(newsItem.publishedAt);
            let formattedDate =  format(articleDate, 'MMMM do, yyyy');
            setItemDate(formattedDate);
        };

        let targetUrl = '/news/item/' + storyId + '/detail';

        getContent({url: targetUrl }, transformResponse);


    }, [storyId]);



    return (
        <>
            <Container fluid>
                <Row className="pageBody" >
                    <Col className="d-none d-md-block" md={2}><SideBar side="left"/></Col>
                    <Col xs={12} md={8}>
                        <Card>
                            <Card.Header>
                                {newsItem.title}
                                <span className="news-date">{itemDate}</span>
                            </Card.Header>
                        <Row>
                            <Col className="d-none d-md-block" xs={4}>
                                <Figure className="news-item-image">
                                <Figure.Image
                                    width={250}
                                    height={250}
                                    alt="image"
                                    src={newsItem.image}
                                />
                                <Figure.Caption>{newsItem.caption}</Figure.Caption>
                            </Figure>
                        </Col>
                            <Col md={8}>
                                <Card.Text as="div"><ReactMarkdown>{newsItem.intro}</ReactMarkdown></Card.Text>
                                <Card.Text as="div"><ReactMarkdown>{newsItem.body}</ReactMarkdown></Card.Text>
                            </Col>
                        </Row>
                        </Card>
                        <p>&nbsp;</p>
                        <p>&nbsp;More stories</p>
                        <Row>
                            {otherStories.map(story => {
                                return (
                                    <Col key={story.id} md={4}>
                                    <Card>
                                        <Card.Header>{story.title}</Card.Header>
                                        <Card.Text>{story.intro}</Card.Text>
                                        <Link className="other-story-link" to={'/news/' + story.id + '/' + story.slug}>read more</Link>
                                    </Card>
                                    </Col>
                                )
                            })}
                            <Col md={4}>
                                <Card>
                                    <Card.Text>To see more of our news, please <Link to={'/news'}>click here</Link></Card.Text>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}><NewsArchiveControl /></Col>
                </Row>
            </Container>
        </>
    )
}