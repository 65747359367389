import React, {useEffect, useState} from 'react';
import useHttp from "../hooks/use-http";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";

export const ArticleTagsControl = () => {

    const [controlList, setControlList] = useState([]);

    const {error, isLoading, sendRequest: getContent} = useHttp();

    useEffect(() => {

        const transformResponse = (data) => {
            console.log(data);

            setControlList(data.payload.tags);
        };


        getContent({url: '/articles/tags/list'}, transformResponse);
    }, []);

    return (
        <Card>
            <Card.Body>
            <p>Filter articles by tag</p>
            {!isLoading && !error && controlList.map(tag => {
                return (
                    <span key={tag.slug}>
                        <Link to={"/articles/tags/" + tag.slug}>{tag.name}</Link>&nbsp;&nbsp;
                    </span>
                )
            })}
            </Card.Body>
        </Card>
    )
}