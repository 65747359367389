import React from 'react';

import './EventItem.css';
import EventDate from './EventDate';
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

export default function EventItem(props) {

    const eventDate = new Date(props.date);

    return (
        <Card>
            <Container>
                <Row>
                    <Col md={3}><EventDate eventDate={eventDate} /></Col>
                    <Col md={9}>
                        <Card.Title>{props.title}</Card.Title>
                        <Card.Text>{props.intro}</Card.Text><Card.Text>{props.description}</Card.Text>
                    </Col>
                </Row>
            </Container>
        </Card>
    )
}