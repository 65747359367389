import React from 'react';
import Card from "react-bootstrap/Card";
import Figure from 'react-bootstrap/Figure'
import "./NewsListItem.css";
import {format} from 'date-fns';
import {Link} from "react-router-dom";


export const NewsListItem = (props) => {

    let articleDate = new Date(props.date);
    let formattedDate =  format(articleDate, 'MMMM do, yyyy');

    return (
        <>
            <Card>
                <Card.Header><Link to={'/news/' + props.id + '/' + props.slug}>{props.title}</Link>
                <span className="news-date">{formattedDate}</span>

                </Card.Header>
                <Card.Text as="div"><Figure className="news-image">
                    <Figure.Image
                        width={150}
                        height={150}
                        alt="image"
                        src={props.image}
                    />
                    <Figure.Caption>{props.caption}</Figure.Caption>
                </Figure>{props.intro}

                </Card.Text>
            </Card>
        </>
    )
}