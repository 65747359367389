import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate} from "react-router-dom";

export const Navigation = (props) => {

    const navigate = useNavigate();

    function homeClickHandler() {
        navigate('/');
    }

    function newsClickHandler() {
        navigate('/news');
    }

    function eventsClickHandler() {
        navigate('/events');
    }

    function articlesClickHandler() {
        navigate('/articles');
    }

    function friendsClickHandler() {
        navigate('/articles/item/1/about_the_friends');
    }

    function membershipClickHandler() {
        navigate('/membership');
    }

    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Container fluid>
                    <Nav className="me-auto">
                        <Nav.Link onClick={homeClickHandler}>Home</Nav.Link>
                        <Nav.Link onClick={newsClickHandler}>News</Nav.Link>
                        <Nav.Link onClick={eventsClickHandler}>Events</Nav.Link>
                        <Nav.Link onClick={articlesClickHandler}>About the wood</Nav.Link>
                        <Nav.Link onClick={friendsClickHandler}>About the friends</Nav.Link>
                        <Nav.Link onClick={membershipClickHandler}>Membership</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </>
    )
}
